<template>
  <div>
    <section class="section is-main-section">
      <finance-table ref="table" :settings="table.settings" :filter="table.filter" :page="table.page"/>
    </section>
  </div>
</template>

<script>
import ClientsTableSample from '@/components/ClientsTableSample'
import CardComponent from '@/components/CardComponent'
import RemoveModal from '@/components/RemoveModal'
import {RoleService, SiteService, UserService as Service} from '@/services'

export default {
  name: 'UserList',
  components: {
    CardComponent,
    ClientsTableSample,
  },
  data() {
    return {
      table: {
        filter: [
          {
            name: 'İsim',
            field: 'username',
            type: 'input'
          }
        ],
        page: {
          title: 'Kullanıcı Listesi',
          icon: 'account',
          actions: [
            {
              title: 'Kullanıcı Oluştur',
              type: 'success',
              icon: 'plus',
              perm: 'User.Create',
              action: () => {
                this.$router.push({name: 'userCreateOrUpdate'})
              }
            },
            {
              title: 'Filtrele',
              type: 'info',
              icon: 'sync',
              action: () => {
                this.$refs.table.loadAsyncData()
              }
            }
          ]
        },
        settings: {
          init: false,
          service: Service,
          method: 'list',
          columns: [
            {
              field: 'uuid',
              label: 'ID',
              sortable: true
            },
            {
              field: 'username',
              label: 'Kullanıcı Adı',
              sortable: true
            },
            {
              field: 'type',
              label: 'Tür',
              sortable: true,
              renderHtml: true,
              viewMethod: val => `${this.enums.userTypes[val]}`
            },
            {
              field: 'role.name',
              label: 'Rol',
              sortable: true
            },
            {
              field: 'status',
              label: 'Durum',
              sortable: true,
              renderHtml: true,
              viewMethod: val => `<span class="tag ${val ? 'is-success' : 'is-danger'}">${val ? 'Aktif' : 'Pasif'}</span>`
            },
            {
              field: 'group',
              label: 'Grup',
              sortable: true
            },
            {
              field: 'creator.username',
              label: 'Oluşturan'
            },
            {
              field: 'createdAt',
              label: 'Kayıt T.',
              sortable: true,
              viewMethod: val => this.dateFormat(val, this.dateFormats.normalDateTimeS)
            },
            {
              field: 'lastLoginDate',
              label: 'Login T.',
              sortable: true,
              viewMethod: val => this.dateFormat(val, this.dateFormats.normalDateTimeS)
            },
            {
              field: 'lastUpdateDate',
              label: 'Şifre T.',
              sortable: true,
              viewMethod: val => this.dateFormat(val, this.dateFormats.normalDateTimeS)
            },
            {
              field: 'actions',
              column: 'id',
              label: 'İşlemler',
              sortable: false,
              width: '100px',
              actions: [
                {
                  title: 'Düzenle',
                  type: 'success',
                  column: 'row',
                  action: data => this.$router.push({name: 'userCreateOrUpdate', params: {id: data.uuid}})
                },
                {
                  column: 'row',
                  title: 'Sil',
                  type: 'danger',
                  perm: 'User.Delete',
                  action: data => {
                    this.openModal({
                      component: RemoveModal,
                      props: {
                        id: data.uuid,
                        name: data.name,
                        service: data.service,
                        type: 'Kullanıcı'
                      },
                      close: this.$refs.table.loadAsyncData
                    })
                  }
                }
              ]
            }
          ]
        }
      }
    }
  },
  mounted() {
    if (!this.$store.state.user.siteId) {
      this.getRoles()
      this.getSites()
      this.$refs.table.filter.push({name: 'Grup', field: 'group', type: 'input'})
      this.$refs.table.filter.push({name: 'Type', field: 'type', type: 'enum', enumType: 'userTypes'})
    } else {
      this.$refs.table.filter.push({name: 'Site', field: 'siteId', type: 'input', hidden: true, value: this.$store.state.user.siteId})
      this.$refs.table.filter.push({name: 'Tür', field: 'type', type: 'input', hidden: true, value: 'PANEL'})
    }
    this.$refs.table.loadAsyncData()
  },
  methods: {
    getRoles() {
      RoleService.all({status: 1}).then(({data}) => {
        this.table.filter.unshift({
          type: 'select',
          value: null,
          data,
          field: 'roleId',
          name: 'Rol',
          id: 'id'
        })
      })
    },
    getSites() {
      SiteService.all({status: 1}).then(({data}) => {
        this.table.filter.unshift({
          type: 'select',
          value: null,
          data,
          field: 'siteId',
          name: 'Site'
        })
      })
    },
  }
}
</script>
